<template>
    <div class="container landing-pageContainer">     
      <section class="p-2 outline-box-landingPage ">
        <div>
            <h1>New to  <font color="#00979e">Rx<font color="#ff8000">Ix</font></font>? </h1>
        </div>
         <div>
          <button class="btn btn-blue-color text-white sign1 mt-3" @click="aboutus()"><h3 class="mb-0">Learn about us</h3></button>
         </div>
         <div class="row mt-4">
          <div class="col-4 col-sm-5 col-md-5 col-lg-5" style="padding: 0px !important;"><hr class="solid-line"></div>
          <div class="col-4 col-sm-2 col-md-2 col-lg-2" style="padding: 0px !important;"><h2 class="mb-0">OR</h2></div>
          <div class="col-4 col-sm-5 col-md-5 col-lg-5" style="padding: 0px !important;"><hr class="solid-line"></div>
         </div>
        <div>
            <h3 class="mb-0 mt-4">Choose a user type</h3>
            <h3>to know what we offer</h3>
        </div>
        <div class="buttonToroute">
            <div class="doctorBox" @click="goDoctor" >
                <h3>Doctor</h3>
            </div>
            <div class="publicBox" @click="goPublic">
                <h3>Public</h3>
            </div>
        </div>
      </section>
    </div>
    <MobileNavbar></MobileNavbar>
  </template>
  <script>
import MobileNavbar from "../common-mobile-footer.vue"
  export default {
    components: {
        MobileNavbar
    },
    data() {
      return {
      }
    },
    methods: {
      aboutus(){
      window.scrollTo(0, 0);
      this.$router.push('/aboutus');
      },
        goDoctor(){
      window.scrollTo(0, 0);
      this.$router.push('/doctor');
        },
        goPublic(){
      window.scrollTo(0, 0);
      this.$router.push('/public'); 
        },
        cardSign() {
      window.scrollTo(0, 0);
      this.$router.push('/signup');
    }
  }
}
  </script>
  <style>
    li.nav-item.nav{
    display: none !important;
  }
  .bottom-link-navbar{
    display: none !important;
  }
  .navbar-expand-lg {
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 16%), 0 2px 4px 0 rgb(0 0 0 / 12%)
  }
  .landing-pageContainer{
    margin-top: 8rem !important; 
    text-align: center;
  }
  hr.solid-line{
    border: 1px solid #000 !important;
    opacity: 1;
  }
  button.btn.btn-blue-color.text-white{
    width: fit-content;
  }
  .signInText{
    margin-top: 2.5rem;
  }
    .buttonToroute{
        margin-top: 2rem;
        display: flex;
        justify-content:space-around;
    }
    .doctorBox{
        color: #fff;
        border-radius: 6px;
        cursor: pointer;
        height: 150px;
        width: 150px;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color:#00979e;
    }
    .publicBox{
        color: #fff;
        border-radius: 6px;
        cursor: pointer;
        height: 150px;
        width: 150px;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: #ff8000;
    }
  section.outline-box-landingPage {
    /* border: 2px solid #00979e !important; */
    background-color: #fff;
    width: 425px;
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 10rem; */
  }
  @media screen and (max-width:991px) {
    .landing-pageContainer{
      margin-top: 3.5rem !important;
    }
    section.outline-box-landingPage {
      margin-bottom: 5rem;
    }
    .show{
    display: block !important;
  }
  }
  @media screen and (max-width:576px) {
    .landing-pageContainer{
      margin-top: 2rem !important;
    }
    section.outline-box-landingPage {
      width: fit-content;
    }
    .doctorBox{
        height: 100px;
        width: 100px;
    }
    .publicBox{
        height: 100px;
        width: 100px;
    }
    .buttonToroute{
      margin-top: 1rem;
    }
    .signInText{
    margin-top: 1.5rem;
  }
  }
  </style>